import { UseTable } from '@ultra/share/components/UTable/useTable';
import { apiUsdkGetAgreementList } from '@/api/usdk';
import { useAppStore } from '@/store/modules/app';
const gameInfo = computed(() => useAppStore().gameInfo);
export default (() => {
  const filter = ref({
    cid: undefined
  });
  const tabType = ref(1);
  const getTableData = async () => {
    console.log('tabType', tabType);
    tableLoading.value = true;
    apiUsdkGetAgreementList({
      gid: gameInfo.value.Id,
      cid: filter.value.cid,
      type: tabType.value
    }).then(res => {
      if (res.code === 0) {
        tableData.value = res.data;
      }
    }).finally(() => {
      tableLoading.value = false;
    });
  };
  const {
    tableData,
    tableLoading
  } = UseTable(getTableData);
  return {
    tabType,
    filter,
    getTableData,
    tableData,
    tableLoading
  };
});